export default ({axios}) => {
    class buyerRewardShippings {
        static get(searchModel){
            return axios.post("/buyerRewardShippingAddress/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/buyerRewardShippingAddress/validatoinRules").then(({data}) => {
                return data
            })
        }
    }

    return buyerRewardShippings
}