export default ({axios, constants}) => {
    class buyerReviews {
        static get(searchModel){
            return axios.post("/buyerReview/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/buyerReview/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/buyerReview/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/buyerReview/remove",{id}).then(({data}) => data)
        }

        static getRatingTexts(){
            return axios.post("/buyerReview/getRatingTexts").then(({data}) => {
                return data
            })
        }

        static getRatingRange(record){
            if(record.rating){
                const {ratingRange} = constants.buyerRewards
                return ratingRange.find(m => record.rating >= m.min && record.rating <= m.max)
            }

            return null
        }

        static getRatingStyles(record){
            const range = buyerReviews.getRatingRange(record)
            if(range){
                if(range.key === "low"){
                    return "color-danger"
                }
                else if(range.key === "moderate")
                {
                    return "color-warning"
                }
                else if(range.key === "high"){
                    return "color-success"
                }
                else{
                    return "color-info"
                }
            }

            return null
        }

        static validateReviewIdExisted(value, model){
            return axios.post("/buyerReview/validateReviewIdExisted", {value, model}).then(({data}) => {
                return data
            })
        }

        static validateReviewOrderIdExisted(value, model){
            return axios.post("/buyerReview/validateReviewOrderIdExisted", {value, model}).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/buyerReview/validatoinRules").then(({data}) => {
                return data
            })
        }
    }

    return buyerReviews
}
