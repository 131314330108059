export default ({axios}) => {
  class UserGroups{
    static get(searchModel) {
      return axios.post("/userGroup/get", searchModel).then(({data}) => {
        return data
      })
    }
    
    static create(model) {
      return axios.post("/userGroup/create", model).then(({data}) => {
        return data
      })
    }
    
    static update(model) {
      return axios.post("/userGroup/update", model).then(({data}) => {
        return data
      })
    }
    
    static remove(id) {
      return axios.post("/userGroup/remove", {id}).then(({data}) => {
        return data
      })
    }

    static getValidations(){
      return axios.post("/userGroup/validatoinRules").then(({data}) => {
          return data
      })
  }

  static validateNameExisted(data){
      return axios.post("/userGroup/validateNameExisted", data).then(({data}) => {
          return data
      })
  }
  }

  return UserGroups
}