export default ({axios}) => {
    const statusStyles = {
        deliverable: "color-success",
        undeliverable: "color-danger",
        risky: "color-warning",
        unknown: "color-info"
    }
    class buyerEmailVerifications{
        static get(searchModel){
            return axios.post("/buyerEmailVerification/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getStatusStyle(record){
            return statusStyles[record.result]
        }
    }
    return buyerEmailVerifications
}