import utils from "@/utilities"
const config = utils.getEnvConfig();

export default ({axios}) => {
    return {
        namespaced: true,
        state:{
            menus: null,
            currentTabIndex: config.tabs.homeTab.menuId,
            homeTabMenuId: config.tabs.homeTab.menuId,
            openedTabs: [
                config.tabs.homeTab
            ]
        },
        getters:{
            isLoaded: state => !!state.menus,
            //only 2 level depth allowed in left-side menu
            getSubMenus: state => parentId => {
                return state.menus.then((menus) => {
                    const l2Menus = menus.filter(menu => menu.parentId === parentId && !menu.hidden);

                    return l2Menus.map(menu => {
                        menu.children = menus.filter(m => m.parentId === menu.menuId && !m.hidden);

                        return menu;
                    })
                });
            },
            getCurrentTabIndex (state) {
                return state.currentTabIndex
            },
            getHomeTabMenuId (state) {
                return state.homeTabMenuId
            },
            getOpenedTabs (state) {
                return state.openedTabs
            },
            getCurrentTab (state) {
                return state.openedTabs.find(i => i.menuId === state.currentTabIndex)
            },
            getQuery (state) {
                const currentTab = state.openedTabs.find(i => i.menuId === state.currentTabIndex)

                if(currentTab){
                    return currentTab.query || {};
                }
            },
            getTab: state => menuId => {
                return state.menus.then(menus => menus.find(menu => menu.menuId === menuId));
            },
            getOpenedTab: state => menuId =>{
                return state.openedTabs.find(menu => menu.menuId === menuId)
            }
        },
        mutations: {
            initMenus(state){
                state.menus = axios.post("/menu/get").then(({data}) => {
                    return data.data.map(menu => {
                        menu.isLoading = false
                        return menu
                    })
                })
            },
            setHash (state) {
                let cur = state.openedTabs.find(i => i.menuId === state.currentTabIndex)
                location.hash = utils.B64Encode(JSON.stringify(cur))
            },
            setCurrentTabIndex (state, data) {
                state.currentTabIndex = data
                this.commit('frame/setHash')
            },
            openedSubTabsPush (state, item) {
                state.openedTabs.map(i => {
                    if (i.menuId === state.currentTabIndex) {
                        i.components.push(item)
                    }
                })
                this.commit('frame/setHash')
            },
            openedTabsRemove (state, menuId) {
                state.openedTabs = state.openedTabs.filter(item => {
                    return item.menuId === state.homeTabMenuId || item.menuId !== menuId
                })

                // 查询当前标签是否被关闭，如果被关闭，则打开最后一个标签
                state.currentTabIndex = state.openedTabs[state.openedTabs.length - 1].menuId;

                this.commit('frame/setHash')
            },
            openedTabsPush (state, item) {
                // 设置当前要显示的 tab name
                state.currentTabIndex = item.menuId
                // 判断 tab 项是否已存在

                let tabExsit = state.openedTabs.find(i => i.menuId === item.menuId)

                if (!tabExsit) {
                    state.openedTabs.push(Object.assign({}, item, {
                        components: item.components ? item.components : [{path: item.component}]
                    }));
                } else if(item.fromHistory) {// 点击前进后退按钮
                    state.openedTabs.map(i => {
                        if(i.menuId === item.menuId) {
                            i.components = item.components
                        }
                    })
                }else{
                    if(item.query){
                        tabExsit.query = item.query
                    }
                }

                if (!item.fromHash) this.commit('frame/setHash')
            },
            openedSubTabsBack (state, num = 1) {
                if (num < 1) num = 1
                state.openedTabs.map(i => {
                    if (i.menuId === state.currentTabIndex) {
                        let newLength = i.components.length - ~~num
                        if (newLength > 0) {
                            i.components = i.components.slice(0, newLength)
                        }
                    }
                })
                this.commit('frame/setHash')
            },
            openedSubTabsReplace (state, item) {
                let index = state.openedTabs.length - 2
                state.openedTabs.map(i => {
                    if (i.menuId === state.currentTabIndex) {
                        i.components.splice(index, 1, item)
                    }
                })
                this.commit('frame/setHash')
            },
            closeAllTabs (state) {
                state.openedTabs.length = 1
                state.currentTabIndex = state.homeTabMenuId
                this.commit('frame/setHash')
            },
            closeOthersTabs (state) {
                state.openedTabs = state.openedTabs.filter(item => {
                    return item.menuId === state.homeTabMenuId || item.menuId === state.currentTabIndex
                })
                this.commit('frame/setHash')
            },
            reShowHash (state) {
                let url = location.href
                let indexOfSharp = url.indexOf('#')
                if (indexOfSharp > 0) {
                    let hash = url.substr(indexOfSharp + 1)
                    let tab = JSON.parse(utils.B64Decode(hash))
                    if (tab.menuId === state.homeTabMenuId) {
                        state.openedTabs = state.openedTabs.filter(i => i.menuId !== state.homeTabMenuId)
                    }
                    state.openedTabs.push(tab)
                    state.currentTabIndex = tab.menuId
                }
            },
            logout(state) {
                state.menus = null
                state.currentTabIndex = config.tabs.homeTab.menuId,
                state.homeTabMenuId = config.tabs.homeTab.menuId,
                state.openedTabs = [
                    config.tabs.homeTab
                ]
            }
        },
        actions:{
            getTopMenus({commit, state}){
                if(!state.menus){
                    commit("initMenus");
                }

                return state.menus.then(menus => menus.filter(menu => !menu.parentId && !menu.hidden));
            },
            logout({commit}) {
                commit('logout');
            }
        }
    }
}