import {default as utils} from "@/utilities";

export default ({axios}) => {
    class amzCrawlerReviews{
        static get(searchModel){
            return axios.post("/amzCrawler.review/get",searchModel).then(({data}) => {
                return data
            })
        }

        static putReviewTags(model){
            return axios.post("/amzCrawler.review/putReviewTags",model).then(({data}) => {
                return data
            })
        }

        static getStateStyleType(review){
            const {state} = review
            if(state === "Origin"){
                return "info"
            }
            else if(state === "Normal"){
                return null
            }
            else if(state === "Deleted"){
                return "danger"
            }
            else if(state === "Restored"){
                return null
            }
            else if(state === "NegToPos"){
                return "success"
            }
            else if(state === "PosToNeg"){
                return "warning"
            }
        }

        static export(searchModel, fileName){
            return axios.post("/amzCrawler.review/export", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }
    }

    return amzCrawlerReviews
}
