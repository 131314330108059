export default ({axios}) => {
    class feeCalculator{
        static calculatePaymentTypeFee(model){
            return axios.post("/feeCalculator/calculatePaymentTypeFee",model).then(({data}) => {
                return data
            })
        }

        static calculateReviewOrderFees(model){
            return axios.post("/feeCalculator/calculateReviewOrderFees",model).then(({data}) => {
                return data
            })
        }

        static calculateReviewRecordFees(model){
            return axios.post("/feeCalculator/calculateReviewRecordFees",model).then(({data}) => {
                return data
            })
        }
    }

    return feeCalculator
}
/*
import {evaluate, round} from "mathjs"
import paymentTypes from "@/dao/paymentTypes"
import currencies from "@/dao/currencies"
import reviewTasks from "@/dao/reviewTasks"
import utilities from "@/utilities"
import {groupBy, sumBy} from "lodash"

class feeCalculator {
    static replaceExpSymbols(expression, scope){
        let exp = expression.exp
        Object.keys(expression.symbols).forEach(key => {
            exp = exp.replace(new RegExp(key,"gm"), `{${expression.symbols[key]}: ${scope[key]}}`)
        })

        return exp
    }

    static async calculatePaymentTypeFee(amount, paymentType, currency){
        let found = false
        const paymentTypeFeeExp = paymentType.feeExpression && paymentType.feeExpression[currency.id] ? paymentType.feeExpression[currency.id] : null
        const scope = {
            amount: round(amount, 2)
        }
        let fee = 0

        if(paymentTypeFeeExp){
            found = true
            fee = round(evaluate(paymentTypeFeeExp.exp, scope), 2)
        }

        const results = await utilities.fakeFetch({
            data: {
                found: found,
                amount: fee,
                currency: currency,
                expression: paymentTypeFeeExp ? feeCalculator.replaceExpSymbols(paymentTypeFeeExp, scope) + ` = ${utilities.formatCurrencyAmount(fee, currency)}` : null
            },
            errors: []
        })

        return results
    }

    static async calculateReviewOrderFees(reviewOrder){
        const paymentType = await paymentTypes.get(reviewOrder.payoutPaymentTypeId)
        const expression = {
            exp: "amount * currencyRate + payoutCommissionFee + paymentTypeFee",
            symbols: {
                amount: "产品本金",
                orderQuantityPlaned: "送测数量",
                payoutCommissionFee: "佣金",
                paymentTypeFee: `${paymentType.data[0].name}手续费`,
                currencyRate: "汇率"
            }
        }

        const currency = await currencies.get(reviewOrder.payoutCurrencyId)
        const paymentTypeFee = await feeCalculator.calculatePaymentTypeFee(reviewOrder.amount, paymentType.data[0], currency.data[0])
        const scope = {
            amount: reviewOrder.amount,
            payoutCommissionFee: reviewOrder.payoutCommissionFee ? reviewOrder.payoutCommissionFee : 0,
            paymentTypeFee: paymentTypeFee.data.found ? paymentTypeFee.data.amount : 0,
            currencyRate: reviewOrder.currencyRate ? reviewOrder.currencyRate : 1
        }
        const amount = evaluate(expression.exp, scope)

        const results = await utilities.fakeFetch({
            data: {
                currency: currency.data[0],
                amount: round(amount, 2),
                expression: expression ? feeCalculator.replaceExpSymbols(expression, scope) : null
            },
            errors: []
        })

        return results
    }

    static async calculateReviewRecordFees(reviewRecord){
        const getOrderExps = async () => {
            const grouped = groupBy(reviewRecord.amzOrders, "payoutCurrencyId")
            const currencyPromises = await Promise.all(Object.keys(grouped).map(key => currencies.get(key)))
            const allCurrencies = currencyPromises.reduce((accu, current) => {
                accu.splice(0,0,current.data[0])
                return accu
            }, [])

            return reviewRecord.amzOrders.map(order => {
                const currency = allCurrencies.find(item => item.id === order.payoutCurrencyId)
                return {
                    amount: order.payoutPrice,
                    currency: currency,
                    expression: `${order.amzOrderId}: ${utilities.formatCurrencyAmount(order.payoutPrice, currency)}`
                }
            })
        }

        const dataPromises = [currencies.get(reviewRecord.payoutCurrencyId), reviewTasks.get({id: reviewRecord.reviewTaskId})]
        if(reviewRecord.payoutPaymentTypeId){
            dataPromises.splice(dataPromises.length, 0, paymentTypes.get(reviewRecord.payoutPaymentTypeId))
        }

        const data = await Promise.all(dataPromises)
        const payoutCurrency = data[0].data[0]
        const marketplace = data[1].data[0].reviewPlan.marketplace

        const symbols = {
            productPrice: "产品本金单价",
            payoutCommissionFee: "佣金",
            paymentTypeFee: data[2] ? `${data[2].data[0].name}手续费` : null,
            currencyRate: "汇率",
            ordersFee: "订单总额",
            orderQuantityPlaned: "送测数量"
        }
        const results = {
            orders: [],
            amount: null,
            currency: null,
            expression: null,
            numOfPaymentslips : 1,
            extra: []
        }

        if(reviewRecord.paymentType === "ordered"){
            //个人-返款
            if(reviewRecord.channelType === "individual"){
                results.numOfPaymentslips = reviewRecord.amzOrders.length
                results.orders = await getOrderExps()
            }
            //中介-返款
            else if(reviewRecord.channelType === "agent"){
                //中介-返款-单独付款
                if(reviewRecord.paymentSlipStrategy === "individual"){
                    results.orders = await getOrderExps()
                    results.numOfPaymentslips = reviewRecord.amzOrders.length

                    //如果独立生成付款单，并且有佣金则单独生成佣金单
                    if(reviewRecord.payoutCommissionFee && reviewRecord.payoutCommissionFee > 0){
                        const expression = {
                            exp: "payoutCommissionFee",
                            symbols: symbols

                        }
                        const scope = {payoutCommissionFee: reviewRecord.payoutCommissionFee}
                        results.expression = feeCalculator.replaceExpSymbols(expression, scope)
                        results.amount = evaluate(expression.exp, scope)
                        results.currency = payoutCurrency
                        results.numOfPaymentslips++
                    }
                }
                //中介-返款-统一付款
                else if(reviewRecord.paymentSlipStrategy === "allInOne"){
                    const expression = {
                        exp: "ordersFee + payoutCommissionFee",
                        symbols: symbols
                    }

                    const scope = {payoutCommissionFee: reviewRecord.payoutCommissionFee, ordersFee: sumBy(reviewRecord.amzOrders, "payoutPrice")}
                    results.expression = feeCalculator.replaceExpSymbols(expression, scope)
                    results.amount = evaluate(expression.exp, scope)
                    results.currency = payoutCurrency
                }
            }
        }
        //预付
        else if(reviewRecord.paymentType === "prepaid"){
            const expression = {
                exp: null,
                symbols: symbols
            }
            const scope = {...reviewRecord}
            //预付-佣本齐付
            if(reviewRecord.payoutMethod === "all"){
                expression.exp = payoutCurrency.id != marketplace.currencyId ? "((productPrice * orderQuantityPlaned) * currencyRate) + payoutCommissionFee" : "(productPrice * orderQuantityPlaned) + payoutCommissionFee"
            }
            //预付-先佣后本
            else if(reviewRecord.payoutMethod === "commissionFeeFirst"){
                expression.exp = "payoutCommissionFee"
            }
            //预付-先本后佣
            else if(reviewRecord.payoutMethod === "productFeeFirst"){
                expression.exp = payoutCurrency.id != marketplace.currencyId ? "(productPrice * orderQuantityPlaned) * currencyRate" : "productPrice * orderQuantityPlaned"
            }

            if(reviewRecord.channelType === "individual"){
                const amount = evaluate(expression.exp, scope)
                const paymentTypeFee = await feeCalculator.calculatePaymentTypeFee(amount, data[2].data[0], payoutCurrency)

                if(paymentTypeFee.data.found){
                    expression.exp = expression.exp + " + paymentTypeFee"
                    Object.assign(scope, {
                        paymentTypeFee: paymentTypeFee.data.amount
                    })
                    results.extra.splice(0,0,{
                        title: symbols.paymentTypeFee,
                        desc: paymentTypeFee.data.expression
                    })
                }
            }

            results.expression = feeCalculator.replaceExpSymbols(expression, scope)
            results.amount = evaluate(expression.exp, scope)
            results.currency = payoutCurrency
        }

        if(results.amount){
            Object.assign(results, {
                amount: round(results.amount, 2)
            })
        }

        return await utilities.fakeFetch({
            data: results,
            errors: []
        })
    }
}

export default feeCalculator*/
