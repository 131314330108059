export default ({axios}) => {
    class users{
        static authenticate(email, password){
            return axios.post("/auth/login",{username: email, password}).then(({data}) => {
                return data
            })
        }

        static changePassword(id, oldPassword, newPassword){
            return axios.post("/user/changePassword",{id, oldPassword, newPassword}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/user/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }

        static get(searchModel){
            return axios.post("/user/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getSelf(searchModel){
            return axios.post("/user/getSelf",searchModel).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/user/create", model).then(({data}) => {
                return data
            })
        }

        static update(model, updateRoles){
            return axios.post("/user/update", {model, updateRoles}).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/user/remove", {id}).then(({data}) => {
                return data
            })
        }

        static getRolePermissions(id){
            return axios.post("/user/getPermissions", {id}).then(({data}) => {
                return data
            })
        }

        static toogleDisabled(id){
            return axios.post("/user/toogleDisabled", {id}).then(({data}) => {
                return data
            })
        }
    }

    return users
}