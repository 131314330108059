const routes = Object.create(null)

routes.install = function (vue) {
    vue.component('Home', () => import("@/components/tabs/Home.vue"))
    vue.component('ChangePwd', () => import("@/components/tabs/changePwd.vue"))
    vue.component('MyProfile', () => import("@/components/tabs/myProfile.vue"))
    vue.component('Region', () => import("@/components/tabs/regions.vue"))
    vue.component('RegionEdit', () => import("@/components/tabs/regionEdit"))
    vue.component('Currency', () => import("@/components/tabs/currencies.vue"))
    vue.component('CurrencyEdit', () => import("@/components/tabs/currencyEdit.vue"))
    vue.component('PaymentTypes', () => import("@/components/tabs/paymentTypes.vue"))
    vue.component('PaymentTypesEdit', () => import("@/components/tabs/paymentTypesEdit.vue"))
    vue.component('AmzMarketplaces', () => import("@/components/tabs/amzMarketplaces"))
    vue.component('AmzMarketplaceEdit', () => import("@/components/tabs/amzMarketplaceEdit.vue"))
    vue.component('AmzStores', () => import("@/components/tabs/amzStores.vue"))
    vue.component('AmzStoreEdit', () => import("@/components/tabs/amzStoreEdit.vue"))
    vue.component('Products', () => import("@/components/tabs/products.vue"))
    vue.component('ProductEdit', () => import("@/components/tabs/productEdit.vue"))
    vue.component('ProductTypes', () => import("@/components/tabs/productTypes.vue"))
    vue.component('ProductTypesEdit', () => import("@/components/tabs/productTypesEdit.vue"))
    vue.component('AmzASINs', () => import("@/components/tabs/amzASINs.vue"))
    vue.component('AmzASINEdit', () => import("@/components/tabs/amzASINEdit.vue"))
    vue.component('Roles', () => import("@/components/tabs/roles.vue"))
    vue.component('RoleEdit', () => import("@/components/tabs/roleEdit.vue"))
    vue.component('Users', () => import("@/components/tabs/users.vue"))
    vue.component('UserEdit', () => import("@/components/tabs/userEdit.vue"))
    vue.component('UserGroups', () => import("@/components/tabs/userGroups.vue"))
    vue.component('UserGroupEdit', () => import("@/components/tabs/userGroupEdit.vue"))
    vue.component('userSource', () => import("@/components/tabs/userSource.vue"))
    vue.component('ApprovalFlows', () => import("@/components/tabs/approvalFlows.vue"))
    vue.component('ApprovalFlowEdit', () => import("@/components/tabs/approvalFlowEdit.vue"))
    vue.component('ReviewChannels', () => import("@/components/tabs/reviewChannels.vue"))
    vue.component('ReviewChannelEdit', () => import("@/components/tabs/reviewChannelEdit.vue"))
    vue.component('PaymentAccounts', () => import("@/components/tabs/paymentAccounts.vue"))
    vue.component('PaymentAccountEdit', () => import("@/components/tabs/paymentAccountEdit.vue"))
    vue.component('ReviewPlans', () => import("@/components/tabs/reviewPlans.vue"))
    vue.component('ReviewPlanEdit', () => import("@/components/tabs/reviewPlanEdit.vue"))
    vue.component('ReviewPlanDetail', () => import("@/components/tabs/reviewPlanDetail.vue"))
    vue.component('ReviewPlanApproval', () => import("@/components/tabs/reviewPlanApproval.vue"))
    vue.component('ReviewTasks', () => import("@/components/tabs/reviewTasks.vue"))
    vue.component('ReviewTaskEdit', () => import("@/components/tabs/reviewTaskEdit.vue"))
    vue.component('ReviewRecords', () => import("@/components/tabs/reviewRecords.vue"))
    vue.component('ReviewRecordEdit', () => import("@/components/tabs/reviewRecordEdit.vue"))
    vue.component('ReviewOrders', () => import("@/components/tabs/reviewOrders.vue"))
    vue.component('BuyerReviews', () => import("@/components/tabs/buyerReviews.vue"))
    vue.component('ReviewOrderEdit', () => import("@/components/tabs/reviewOrderEdit.vue"))
    vue.component('PaymentSlips', () => import("@/components/tabs/paymentSlips.vue"))
    vue.component('PaymentSlipEdit', () => import("@/components/tabs/paymentSlipEdit.vue"))
    vue.component('BuyerReviewEdit', () => import("@/components/tabs/buyerReviewEdit.vue"))
    vue.component('Buyers', () => import("@/components/tabs/buyers.vue"))
    vue.component('BuyerEdit', () => import("@/components/tabs/buyerEdit.vue"))
    vue.component('MyPayments', () => import("@/components/tabs/myPayments.vue"))
    vue.component('Countries', () => import("@/components/tabs/countries.vue"))
    vue.component('CountryEdit', () => import("@/components/tabs/countryEdit.vue"))
    vue.component('Gifts', () => import("@/components/tabs/gifts.vue"))
    vue.component('GiftEdit', () => import("@/components/tabs/giftEdit.vue"))
    vue.component('BuyerRewards', () => import("@/components/tabs/buyerRewards.vue"))
    vue.component('BuyerRewardAdd', () => import("@/components/tabs/buyerRewardAdd.vue"))
    vue.component('BuyerRewardEdit', () => import("@/components/tabs/buyerRewardEdit.vue"))
    vue.component('BuyerRewardImport', () => import("@/components/tabs/buyerRewardImport.vue"))
    vue.component('Orders', () => import("@/components/tabs/orders.vue"))
    vue.component('serverlessFlows', () => import("@/components/tabs/serverlessFlows.vue"))
    vue.component('serverlessSchedulesCreate', () => import("@/components/tabs/serverlessSchedulesCreate.vue"))
    vue.component('importHistoricalReviewOrders', () => import("@/components/tabs/importHistoricalReviewOrders.vue"))
    vue.component('ReviewTags', () => import("@/components/tabs/reviewTags.vue"))
    vue.component('BrandEdit', () => import("@/components/tabs/brandEdit.vue"))
    vue.component('Brands', () => import("@/components/tabs/brands.vue"))
    vue.component('BrandMerge', () => import("@/components/tabs/brandMerge.vue"))
    vue.component('BrandMergeEdit', () => import("@/components/tabs/brandMergeEdit.vue"))
    vue.component('AsinWatchedEdit', () => import("@/components/tabs/asinWatchedEdit.vue"))
    vue.component('AsinsWatched', () => import("@/components/tabs/asinsWatched.vue"))
    vue.component('keywordRank', () => import("@/components/tabs/keywordRank.vue"))
    vue.component('ReviewTagsPut', () => import("@/components/tabs/reviewTagsPut.vue"))
    vue.component('AsinEdit', () => import("@/components/tabs/asinEdit.vue"))
    vue.component('Manufacturers', () => import("@/components/tabs/manufacturers.vue"))
    vue.component('ManufacturerEdit', () => import("@/components/tabs/manufacturerEdit.vue"))
    vue.component('ManufacturerModels', () => import("@/components/tabs/manufacturerModels.vue"))
    vue.component('ManufacturerSpecEdit', () => import("@/components/tabs/manufacturerSpecEdit.vue"))
    vue.component('DataHits', () => import("@/components/tabs/dataHits.vue"))
    vue.component('AmzReviewsProcess', () => import("@/components/tabs/amzReviewsProcess.vue"))
    vue.component('AmzReviewsIncrementDaily', () => import("@/components/tabs/amzReviewsIncrementDaily.vue"))
    vue.component('AmzReviewsImprovements', () => import("@/components/tabs/amzReviewsImprovements.vue"))
    vue.component('AmzRatingsForecast', () => import("@/components/tabs/amzRatingsForecast.vue"))
    vue.component('BrowseTreeCategories', () => import("@/components/tabs/browseTreeCategories.vue"))
    vue.component('BSRWatched', () => import("@/components/tabs/bSRWatched.vue"))
    vue.component('AmzReviewsIncrementGroup', () => import("@/components/tabs/amzReviewsIncrementGroup.vue"))
}

export default routes
