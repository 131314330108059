const lodash = require("lodash")
export default ({axios, constants}) => {
    class mwsListings{
        static get(searchModel){
            return axios.post("/mws.listing/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getMatchingProduct(model){
            return axios.post("/mws.listing/getMatchingProduct",model).then(({data}) => {
                return data
            })
        }

        static decodeLastDetailsError(asinDetail){
            const result = {}
            const {lastUpdatedState, lastUpdatedError} = asinDetail
            if(!lodash.isEmpty(lastUpdatedState)){
                result.type = lastUpdatedState
                if(result.type === "success"){
                    result.message = "页面正常"
                }
                else if(result.type === "failed"){
                    result.message = "页面异常"
                }
            }

            if(!lodash.isEmpty(lastUpdatedError)){
                const {status, Error} = lastUpdatedError
                if(status && Error){
                    if(status === "ClientError" && Error.Code === "InvalidParameterValue"){
                        result.message = "ASIN详情页不存在"
                    }
                    else if(!lodash.isEmpty(Error.Message)){
                        result.message = Error.Message
                    }
                    else{
                        result.message = "未知错误"
                    }
                }
                else{
                    result.message = "未知错误"
                }
            }

            return lodash.isEmpty(result) ? null : result
        }

        static getRelationTypes(listing){
            const {relationType, parentId} = listing
            const getStyle = () => {
                if(relationType === "sync"){
                    return "warning"
                }
                else if(relationType === "offer"){
                    return null
                }
            }
            const types = []

            const {relationTypes} = constants.listings
            if(relationType){
                types.push({
                    name: relationTypes[relationType],
                    style: getStyle()
                })
            }

            if(parentId){
                types.push({
                    name: "变体",
                    style: "success"
                })
            }

            return types
        }
    }

    return mwsListings
}
