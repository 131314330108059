export default ({axios}) => {
    class productTypes {
        static get(searchModel){
            return axios.post("/productType/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/productType/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/productType/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/productType/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/productType/validatoinRules").then(({data}) => {
                return data
            })
        }
    }

    return productTypes
}