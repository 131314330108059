export default ({axios}) => {
    class asins{
        static get(searchModel){
            return axios.post("/asin/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/asin/update", model).then(({data}) => {
                return data
            })
        }

        static getByConditions(conditions) {
            return axios.post("/asin/getByConditions", conditions).then(({data}) => {
                return data
            })
        }
    }

    return asins
}
