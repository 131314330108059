import Vue from 'vue'
import Vuex from 'vuex'

export default ({axios}) =>{
  Vue.use(Vuex)
  const auth = require("@/stores/authentication").default({axios})
  const frame = require("@/stores/frame").default({axios})
  const serverProps = require("@/stores/serverProps").default({axios})
  const vuex = new Vuex.Store({
    modules:{
      auth,
      frame,
      serverProps
    }
  })

  return vuex
}
