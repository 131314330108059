export default ({axios}) => {
    class paymentTypes {
        static get(searchModel){
            return axios.post("/paymentType/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/paymentType/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/paymentType/create", model).then(({data}) => {
                return data
            })
        }

        static toogleStatus(id){
            return axios.post("/paymentType/toogleStatus", {id}).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/paymentType/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/paymentType/validatoinRules", {model}).then(({data}) => {
                return data
            })
        }

        static getPayResultsField(paymentType, key){
            if(paymentType.payResultsConfig && paymentType.payResultsConfig.fields){
                const config = paymentType.payResultsConfig.fields[key]
                if(config){
                    return config.name
                }
            }

            return key
        }
    }

    return paymentTypes
}