import {default as utils} from "../utilities";

export default ({axios}) => {
    class reviewTags{
        static get(searchModel){
            return axios.post("/reviewTag/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/reviewTag/validatoinRules").then(({data}) => {
                return data
            })
        }

        static validateIdExisted(data){
            return axios.post("/reviewTag/validateIdExisted", data).then(({data}) => {
                return data
            })
        }

        static validateNameExisted(data){
            return axios.post("/reviewTag/validateNameExisted", data).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/reviewTag/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewTag/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/reviewTag/remove",{id}).then(({data}) => {
                return data
            })
        }
    }

    return reviewTags
}
