export default ({axios}) => {
    class brands{
        static get(searchModel){
            return axios.post("/brand/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/brand/validatoinRules").then(({data}) => {
                return data
            })
        }

        static validateNameTypeExisted(data){
            return axios.post("/brand/validateNameTypeExisted", data).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/brand/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/brand/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/brand/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getMergeRelation(searchModel) {
            return axios.post("/brand/getMergeRelation", searchModel).then(({data}) => {
                return data
            })
        }

        static mergeBrands(models) {
            return axios.post("/brand/mergeBrands", models).then(({data}) => {
                return data
            })
        }

        static handleBreak(id) {
            return axios.post("/brand/breakBrands", {id}).then(({data}) => {
                return data
            })
        }
    }

    return brands
}
