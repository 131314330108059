const utils = require("../utilities").default
const orderStatusStylesMap = {
    Pending: "color-warning",
    Unshipped: "color-warning",
    PartiallyShipped: "color-warning",
    PendingAvailability: "color-warning",
    Shipped: "color-success",
    Canceled: "color-danger"
}
const validate_errors_desc = {
    OrderNotFound: "数据库中未找到订单",
    OrderItemEmpty: "没有获取到该订单货件信息",
    Conflict_BuyerReward: "存在索取过好评卡记录",
    Conflict_ReviewOrder: "存在送测记录"
}
export default ({axios}) => {
    class mwsOrders{
        static get(searchModel){
            return axios.post("/mws.order/get",searchModel).then(({data}) => {
                return data
            })
        }

        static export(searchModel, fileName){
            return axios.post("/mws.order/export", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }

        static getOrderStatusStyle(status){
            return orderStatusStylesMap[status] || "color-warning"
        }

        static validate(amzOrderId, storeId, marketplaceId,reviewOrderId){
            return axios.post("/mws.order/validate",{amzOrderId, storeId, marketplaceId, reviewOrderId}).then(({data}) => {
                return data
            })
        }

        static getOrderValidateErrorDesc(errorCode){
            return validate_errors_desc[errorCode]
        }
    }

    return mwsOrders
}
