const RouterMethods = Object.create(null);

function processData (item, payload) {
    const newItem = Object.assign({}, item)

    if(payload){
        newItem.query = Object.assign({}, newItem.query, payload)
    }
    return newItem
}

RouterMethods.install = function (Vue, options) {
    Vue.prototype.$tab = {
        getCurrentTab(){
            return options.store.getters["frame/getCurrentTab"];
        },
        refreshTab(tab){
            let c = tab.components
            tab.components = []
            Vue.nextTick(() => {
                tab.components = c
            })
        },
        refresh(){
            this.refreshTab(this.getCurrentTab())
        },
        // 设置当前显示的 tab name
        showTab(data) {
            options.store.commit('frame/setCurrentTabIndex', data)
        },
        // 打开新的 tab  项
        open (item) {
            options.store.commit('frame/openedTabsPush', item)
        },
        openById (id,payload) {
            const doOpen = (tab, refresh) => {
                let newItem = processData(tab, payload)
                const query = newItem.query
                const queryEqual = Vue.prototype.$_.isEqual(query, tab.query)
                options.store.commit('frame/openedTabsPush', newItem)

                if(refresh){
                    if(query && query.refresh && !queryEqual){
                        Vue.nextTick(() => {
                            this.refresh()
                        })
                    }
                }
            }
            const openedTab = options.store.getters["frame/getOpenedTab"](id)

            if(!openedTab){
                options.store.getters["frame/getTab"](id).then(tab => {
                    doOpen(tab)
                })
            }
            else{
                doOpen(openedTab, true)
            }
        },
        // 删除 tab 项
        close (menuId) {
            options.store.commit('frame/openedTabsRemove',menuId)
        },
        // 跳转
        push(item,payload) {
            let newItem = processData(item,payload)
            options.store.commit('frame/openedSubTabsPush',newItem)
        },
        // 后退
        back(num) {
            options.store.commit('frame/openedSubTabsBack',num)
        },
        // 替换
        replace(item,payload) {
            let newItem = processData(item,payload)
            options.store.commit('frame/openedSubTabsReplace',newItem)
        },
        // 关闭所有 tab
        closeAll () {
            options.store.commit('frame/closeAllTabs')
        },
        // 关闭其他标签
        closeOthers () {
            options.store.commit('frame/closeOthersTabs')
        },
        // 根据浏览器的 url 回显 tab
        reShow() {
            options.store.commit('frame/reShowHash')
        },
        // 获取当前组件的查询参数
        query () {
            return options.store.getters["frame/getQuery"]
        }
    }
}

export default RouterMethods