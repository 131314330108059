<template>
    <svg class="flag-icon" aria-hidden="true" :style="this.fontSize ? `font-size: ${this.fontSize}px` : ''">
        <use :xlink:href="`#flags-${this.icon}`"></use>
    </svg>
</template>

<script>
    import "@/assets/css/icon-flags.css"
    import '@/assets/flags/icon-flags';
    export default {
        props:{
            icon: {
                type: String,
                required: true
            },
            fontSize:{
                type: Number
            }
        }
    }
</script>