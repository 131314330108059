export default ({axios}) => {
    class mwsAsinDetails{
        static get(searchModel){
            return axios.post("/mws.asinDetail/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getAsinDetailDimensions(dimensions){
            const dimensionToString = (dimension) => {
                return `${parseFloat(dimension.Value).toFixed(2)} ${dimension.Units}`
            }

            const parts = []
            if(dimensions.Length){
                parts.push(`长 ${dimensionToString(dimensions.Length)}`)
            }

            if(dimensions.Width){
                parts.push(`宽 ${dimensionToString(dimensions.Width)}`)
            }

            if(dimensions.Height){
                parts.push(`高 ${dimensionToString(dimensions.Height)}`)
            }

            if(dimensions.Weight){
                parts.push(`重量 ${dimensionToString(dimensions.Weight)}`)
            }

            return parts.join(", ")
        }
    }

    return mwsAsinDetails
}
