
export default ({axios}) => {
    class BrowseTreeCategories{
        static get(searchModel){
            return axios.post('mws.browseTreeCategories/get',searchModel).then(({data}) => {
                return data
            })
        }

        static update(postData) {
            return axios.post('mws.browseTreeCategories/update', postData).then(({data}) => data)
        }
    }

    return BrowseTreeCategories
}
