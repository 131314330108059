export default ({axios}) => {
    class serverless{
        static getFlows(model){
            return axios.post("/serverless/getFlows",model).then(({data}) => {
                return data
            })
        }

        static listSchedules(model){
            return axios.post("/serverless/listSchedules",model).then(({data}) => {
                return data
            })
        }

        static toggleSchedules(model){
            return axios.post("/serverless/toggleSchedules",model).then(({data}) => {
                return data
            })
        }

        static deleteSchedules(model){
            return axios.post("/serverless/deleteSchedules",model).then(({data}) => {
                return data
            })
        }

        static createSchedule(model){
            return axios.post("/serverless/createSchedule",model).then(({data}) => {
                return data
            })
        }

        static updateSchedules(model){
            return axios.post("/serverless/updateSchedules",model).then(({data}) => {
                return data
            })
        }

        static getSchedule(model){
            return axios.post("/serverless/getSchedule",model).then(({data}) => {
                return data
            })
        }
    }

    return serverless
}
