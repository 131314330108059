export default ({axios}) => {
    class reviewOrders{
        static get(searchModel){
            return axios.post("/reviewOrder/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(model, opts){
            return axios.post("/reviewOrder/validatoinRules", {model, opts}).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewOrder/create",model).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/reviewOrder/update",model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/reviewOrder/remove",{id}).then(({data}) => {
                return data
            })
        }

        static historicalImportVerify(files){
            return axios.post("/reviewOrder/historicalImportVerify",files).then(({data}) => {
                return data
            })
        }

        static importHistorical(files){
            return axios.post("/reviewOrder/importHistorical",files).then(({data}) => {
                return data
            })
        }

        static getReviewStateStyles(record){
            if(record.reviewState === "waitingForReview"){
                return "color-warning"
            }
            else if(record.reviewState === "none" || record.reviewState === "reviewed")
            {
                return "color-success"
            }
        }

        static getReviewStateStyleTypes(record){
            if(record.reviewState === "waitingForReview"){
                return "warning"
            }
            else if(record.reviewState === "none" || record.reviewState === "reviewed")
            {
                return "success"
            }
        }

        static getPayoutPaymentStateStyles(record){
            if(record.payoutPaymentPercent === 0){
                return "color-danger"
            }
            else if(record.payoutPaymentState === 1)
            {
                return "color-success"
            }
            else {
                return "color-warning"
            }
        }
    }

    return reviewOrders
}
