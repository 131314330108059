export default ({axios}) => {
    class asinsWatched{
        static get(searchModel){
            return axios.post("/asinWatched/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/asinWatched/validatoinRules", model).then(({data}) => {
                return data
            })
        }

        static validateASINExisted(data){
            return axios.post("/asinWatched/validateASINExisted", data).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/asinWatched/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/asinWatched/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/asinWatched/remove",{id}).then(({data}) => {
                return data
            })
        }
    }

    return asinsWatched
}
