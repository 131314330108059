export default ({axios}) => {
    class reviewTasks{
        static get(searchModel){
            return axios.post("/reviewTask/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/reviewTask/validatoinRules").then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/reviewTask/update", model).then(({data}) => {
                return data
            })
        }

        static updateState(model){
            return axios.post("/reviewTask/updateState", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewTask/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/reviewTask/remove",{id}).then(({data}) => {
                return data
            })
        }

        static createByReviewPlan(model){
            return axios.post("/reviewTask/createByReviewPlan",model).then(({data}) => {
                return data
            })
        }

        static getStateStyle(record){
            const styles = {
                onGoing: {
                    color: "color-success",
                    icon: "play-circle"
                },
                paused: {
                    color: "color-warning",
                    icon: "pause-circle"
                },
                terminated: {
                    color: "color-danger",
                    icon: "stop-circle"
                },
                completed:{
                    color: "color-success",
                    icon: "check-circle"
                }
            }

            return styles[record.state]
        }
    }

    return reviewTasks
}
