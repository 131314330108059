export default ({axios}) => {
    class reviewChannels {
        static get(searchModel){
            return axios.post("/reviewChannel/get",searchModel).then(({data}) => data)
        }

        static update(model){
            return axios.post("/reviewChannel/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewChannel/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/reviewChannel/remove",{id}).then(({data}) => data)
        }

        static getValidations(model){
            return axios.post("/reviewChannel/validatoinRules", model).then(({data}) => data)
        }
    }

    return reviewChannels
}