export default ({axios, constants}) => {
    class reviewRecords{
        static get(searchModel){
            return axios.post("/reviewRecord/get",searchModel).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewRecord/create",model).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/reviewRecord/update",model).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/reviewRecord/validatoinRules", {model}).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/reviewRecord/remove",{id}).then(({data}) => {
                return data
            })
        }

        static isPaymentSlipRequired(model){
            return axios.post("/reviewRecord/isPaymentSlipRequired",model).then(({data}) => {
                return data
            })
        }

        static isReviewOrderPaymentSlipRequired(model){
            return axios.post("/reviewRecord/isReviewOrderPaymentSlipRequired",model).then(({data}) => {
                return data
            })
        }

        static getPayoutPaymentStateStyles(record){
            if(record.payoutPaymentPercent === 0){
                return "color-danger"
            }
            else if(record.payoutPaymentState === 1)
            {
                return "color-success"
            }
            else {
                return "color-warning"
            }
        }

        static getReviewStateStyles(record){
            if(record.orderReviewedPercent === 0){
                return "color-danger"
            }
            else if(record.orderReviewedPercent === 1)
            {
                return "color-success"
            }
            else {
                return "color-warning"
            }
        }

        static getTypes(model){
            const {reviewRecords: props} = constants
            const types = {
                channelType: props.channelTypes[model.channelType],
                paymentType: props.paymentTypes[model.paymentType]
            }

            if(model.payoutMethod){
                types.payoutMethod = props.payoutMethods[model.payoutMethod]
            }

            if(model.paymentSlipStrategy){
                types.paymentSlipStrategy = props.paymentSlipStrategies[model.paymentSlipStrategy]
            }

            return types
        }

        static getPayoutMethodsDesc(method){
            const descs = {
                all: "预先支付佣金和本金 - 付款单中包含佣金和本金，要求每个亚马逊订单的支付货币必须与最终支付货币一致，如果不一致则必须提供转换汇率。",
                commissionFeeFirst: "先付佣金再付本金 - 在付款单中只包含佣金，以后可以在该记录下上传亚马逊订单信息，并单独生成产品本金的付款单。",
                productFeeFirst: "先付本金再付佣金 - 在付款单中只包含产品本金，以后可以在该记录下上传亚马逊订单信息，并单独生成佣金的付款单。",
                after: "拿到订单或回评后付款 - 添加测评记录时不生成付款单, 之后单独添加订单和评论时再生成付款单"
            }

            return descs[method]
        }

        static getPaymentSlipStrategiesDesc(strategy){
            const descs = {
                allInOne: "付款单总额中包含所有订单金额，只生成一张付款单。要求每个亚马逊订单的支付货币必须与最终支付货币一致，如果不一致则必须提供转换汇率。",
                individual: "为每个订单单独生成独立付款单，适合中介测评单独返款给买手的情况。"
            }

            return descs[strategy]
        }
    }

    return reviewRecords
}
