export default ({axios}) => {
    class manufacturers{
        static get(searchModel){
            return axios.post("/manufacturer/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/manufacturer/validatoinRules", model).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/manufacturer/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/manufacturer/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/manufacturer/remove",{id}).then(({data}) => {
                return data
            })
        }
    }

    return manufacturers
}
