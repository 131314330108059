export default ({axios}) => {
    const users = require("@/dao/users").default({axios})
    const pick = require("lodash/pick")
    return {
        namespaced: true,
        state: {
            status: "",
            token: localStorage.getItem('token') || '',
            user: JSON.parse(localStorage.getItem('user')) || {}
        },
        mutations: {
            auth_request(state) {
                state.status = 'loading';
            },
            auth_success(state, {token, user}) {
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                state.status = 'success';
                state.token = token;
                state.user = user;
            },
            auth_error(state) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                state.status = 'error';
            },
            logout(state) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                state.status = '';
                state.token = '';
                state.user = {};
            },
            userChange(state, user) {
                const userCopy = pick(user, ["id", "email", "phone", "fullName"])
                userCopy.su = user.isSuperAdmin
                localStorage.setItem('user', JSON.stringify(userCopy));
                state.user = userCopy;
            }
        },
        actions: {
            login({commit}, loginModel) {
                commit('auth_request');
                return users.authenticate(loginModel.email, loginModel.password).then((res) => {
                    if (!res.errors.length) {
                        const {user, token} = res.data;
                        commit('auth_success', {token, user});
                    } else {
                        commit('auth_error');
                    }

                    return res;

                }).catch(() => {
                    commit('auth_error');
                });
            },
            logout({commit}) {
                commit('logout');
                delete axios.defaults.headers.common['Authorization']
            }
        },
        getters: {
            authenticated: state => !!state.token,
            user: state => state.user
        }
    }
}