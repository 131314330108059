<template>
  <font-awesome-icon :icon="this.iconNormalized"></font-awesome-icon>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
  components:{
    FontAwesomeIcon
  },
  props:{
    icon: null
  },
  computed:{
    iconNormalized(){
      if(this.icon){
        if(typeof this.icon === "string" && this.icon.includes(",")){
          return this.icon.split(',').map(s => s.trim())
        }
      }

      return this.icon
    }
  }
}
</script>