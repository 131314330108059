export default ({axios}) => {
    class buyerRewardDeliveries {
        static get(searchModel){
            return axios.post("/buyerRewardDelivery/get",searchModel).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/buyerRewardDelivery/create", model).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/buyerRewardDelivery/validatoinRules").then(({data}) => {
                return data
            })
        }
    }

    return buyerRewardDeliveries
}