export default ({axios}) => {
    class dataHits{
        static get(searchModel){
            return axios.post("/dataHit/get",searchModel).then(({data}) => {
                return data
            })
        }
    }

    return dataHits
}
