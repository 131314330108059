export default ({axios}) => {
    class manufacturerSpecs{
        static get(searchModel){
            return axios.post("/manufacturerSpec/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/manufacturerSpec/validatoinRules", model).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/manufacturerSpec/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/manufacturerSpec/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/manufacturerSpec/remove",{id}).then(({data}) => {
                return data
            })
        }
    }

    return manufacturerSpecs
}
