const lodash = require("lodash")
export default ({axios, constants}) => {
    class mwsFinancialEvents{
        static getEventType(event){
            if(!lodash.isEmpty(event.type)){
                const {eventTypes} = constants.financialEvents
                const eventType = eventTypes[event.type]
                if(!lodash.isEmpty(eventType)){
                    return lodash.pick(eventType, ["name", "sort", "description"])
                }

                return null
            }

            return null
        }

        static getEventSubType(event){
            if(!lodash.isEmpty(event.subType)){
                const {eventTypes} = constants.financialEvents
                const eventType = eventTypes[event.type]
                if(eventType && !lodash.isEmpty(eventType.subTypes)){
                    const eventSubType = eventType.subTypes[event.subType]
                    if(!lodash.isEmpty(eventSubType)){
                        return lodash.pick(eventSubType, ["name", "sort", "description"])
                    }

                    return null
                }

                return null
            }

            return null
        }
    }

    return mwsFinancialEvents
}
