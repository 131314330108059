export default ({axios}) => {
    class asinMarketplaces{
        static get(searchModel){
            return axios.post("/asinMarketplace/get",searchModel).then(({data}) => {
                return data
            })
        }
    }

    return asinMarketplaces
}
