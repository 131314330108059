import {default as utils} from "@/utilities";

export default ({axios}) => {
    class statsReviewDetails{
        static get(searchModel){
            return axios.post("/stats/reviewDetails/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getGroupByDT(searchModel){
            return axios.post("/stats/reviewDetails/getGroupByDT",searchModel).then(({data}) => {
                return data
            })
        }

        static exportGroupByDT(searchModel, fileName){
            return axios.post("/stats/reviewDetails/exportGroupByDT", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }
    }

    return statsReviewDetails
}
