export default ({axios}) => {
    class roles {
        static get(searchModel){
            return axios.post("/role/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/role/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/role/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/role/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getRolePermissions(id){
            return axios.post("/role/getPermissions",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/role/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }
    }

    return roles
}