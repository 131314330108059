export default ({axios}) => {
    class shared {
        static getObjectURL(model){
            return axios.post("/shared/getObjectURL",model).then(({data}) => {
                return data
            })
        }

        static getTimeZones(){
            return axios.post("/shared/getTimeZones").then(({data}) => {
                return data
            })
        }
    }

    return shared
}
