import {default as utils} from "@/utilities";

export default ({axios}) => {
    class statsRatingDetails{
        static get(searchModel){
            return axios.post("/stats/ratingDetails/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getForecastGroupByDt(searchModel){
            return axios.post("/stats/ratingDetails/getForecastGroupByDt",searchModel).then(({data}) => {
                return data
            })
        }

        static exportForecastGroupByDt(searchModel, fileName) {
            return axios.post('/stats/ratingDetails/exportForecastGroupByDt', { queries: searchModel, fileName }, {
                responseType: 'blob'
            }).then(({ data }) => {
                utils.download(data, fileName, 'blob')
            })
        }

        static export(searchModel, fileName){
            return axios.post("/stats/ratingDetails/export", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }

        static getGroupByDT(searchModel){
            return axios.post("/stats/ratingDetails/getGroupByDT",searchModel).then(({data}) => {
                return data
            })
        }

        static exportGroupByDT(searchModel, fileName){
            return axios.post("/stats/ratingDetails/exportGroupByDT", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }
    }

    return statsRatingDetails
}
