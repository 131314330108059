import tabRouter from "@/plugins/tabsRouter"
import tabRoutes from "@/plugins/tabRoutes"
import handler from "@/plugins/globalErrorHandler";
import lodash from "lodash"
import utils from "@/utilities"
import iconLibs from "./iconLibs"
const config = utils.getEnvConfig();

const plugins = Object.create(null)
plugins.install = function (Vue, options){
    Vue.prototype.$config = config;
    Vue.$config = config;
    Vue.prototype.$utils = utils;
    Vue.prototype.$_ = lodash;
    Vue.prototype.$axios = options.axios
    Vue.prototype.$getServerProps = () => options.store.getters["serverProps/getProps"]
    Vue.prototype.$requireDAO = (dao) => require(`../dao/${dao}`).default({axios: options.axios, constants: options.store.getters["serverProps/getProps"]})

    Vue.use(iconLibs)
    Vue.use(handler, options);
    Vue.use(tabRoutes, options);
    Vue.use(tabRouter, options);
}

export default plugins