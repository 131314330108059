export default ({axios}) => {
    class approvalFlows {
        static get(searchModel){
            return axios.post("/approvalFlow/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/approvalFlow/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/approvalFlow/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/approvalFlow/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/approvalFlow/validatoinRules").then(({data}) => {
                return data
            })
        }
    }

    return approvalFlows
}