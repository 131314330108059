import Vue from 'vue'
import App from './App.vue'
import plugins from "@/plugins/index"
import "@/assets/css/base.css"
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import components from '@/components/index'
const config = require("./utilities").default.getEnvConfig()
const axios = require("axios").default.create({
  baseURL: config.webapi.baseUrl
})

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

const store = require('./store').default({axios})
const router = require('./router').default({store})

Vue.use(Element);
Vue.use(components)

Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store,
  render: h => h(App)
});

Vue.use(plugins, {
  vmInstance: vue,
  store,
  axios
});

vue.$mount('#app');