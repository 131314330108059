export default ({axios}) => {
    class approvalFlowStepSnapshots{
        static get(searchModel){
            return axios.post("/approvalFlowStepSnapshot/get",searchModel).then(({data}) => {
                return data
            })
        }
    }

    return approvalFlowStepSnapshots
}
