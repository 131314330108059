export default ({axios}) => {
    class regions{
        static get(searchModel){
            return axios.post("/region/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/region/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/region/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/region/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/region/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }
    }

    return regions
}