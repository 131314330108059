import {default as utils} from "@/utilities";

export default ({axios}) => {
    class keywordRanks{
        static getByAsintype(searchModel){
            return axios.post("/keywordRanks/getByAsintype",searchModel).then(({data}) => {
                return data
            })
        }

        static export(searchModel, fileName){
            return axios.post("/keywordRanks/export", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }
    }

    return keywordRanks
}
