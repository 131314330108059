const dataHitStateClassesMap = {
    unConfirmed: {
        class: "color-warning",
        styleType: "warning"
    },
    confirmed: {
        class: "color-success",
        styleType: "success"
    },
    rejected: {
        class: "color-danger",
        styleType: "danger"
    }
}
export default ({axios}) => {
    class dataHitDetails{
        static get(searchModel){
            return axios.post("/dataHitDetail/get",searchModel).then(({data}) => {
                return data
            })
        }

        static updateState(model){
            return axios.post("/dataHitDetail/updateState",model).then(({data}) => {
                return data
            })
        }

        static getStateClass(dataHitDetail){
            const {state} = dataHitDetail
            return dataHitStateClassesMap[state]
        }
    }

    return dataHitDetails
}
