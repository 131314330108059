export default ({axios}) => {
    class amzStores {
        static get(searchModel){
            return axios.post("/amzStore/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getMWSEnabled(searchModel){
            return axios.post("/amzStore/getMWSEnabled",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/amzStore/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/amzStore/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/amzStore/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/amzStore/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }

        static getStoreStateStyle(store){
            const {state} = store
            if(state === "normal"){
                return "color-success"
            }
            else if(state === "dead"){
                return "color-danger"
            }
        }
    }

    return amzStores
}
