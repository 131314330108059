export default ({ axios }) => {
    class BSRWatched {
        static get(searchModel) {
            return axios.post('mws.BSRWatched/get',searchModel).then(({data}) => {
                return data
            })
        }

        static save(marketplaceId, data) {
            return axios.post('mws.BSRWatched/save', { marketplaceId, data })
                .then(({data}) => data)
                .catch(err => ({ errors: [err] }))
        }
    }

    return BSRWatched
}