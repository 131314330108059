//为了使用fontawesome的tree-shaking特性,需要将项目中使用的所有fontawesome图标枚举出来
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMoneyBillAlt,
    faListAlt,
    faStarHalfAlt,
    faObjectGroup,
    faCopyright,
    faLightbulb,
    faPlusSquare,
    faUser,
    faComments,
    faChartBar,
    faEye,
    faStopCircle,
    faCheckCircle,
    faPlayCircle,
    faPauseCircle
} from "@fortawesome/free-regular-svg-icons";

import {
    faUserGroup,
    faUsersGear,
    faObjectUngroup,
    faStore,
    faCogs,
    faCog,
    faGlobe,
    faGlobeAmericas,
    faCoins,
    faMoneyBillWave,
    faStoreAlt,
    faArchive,
    faBoxes,
    faBoxOpen,
    faBox,
    faProjectDiagram,
    faListUl,
    faBars,
    faCodeBranch,
    faShareAlt,
    faUsers,
    faUserTag,
    faUsersCog,
    faUserFriends,
    faUserPlus,
    faGifts,
    faCubes,
    faCube,
    faHandHoldingUsd,
    faMoneyCheckAlt,
    faMoneyCheck,
    faGift,
    faStamp,
    faTasks,
    faHandHoldingHeart,
    faShoppingBasket,
    faUpload,
    faPeopleCarry,
    faIdCardAlt,
    faLuggageCart,
    faShoppingCart,
    faNetworkWired,
    faClipboardList,
    faStethoscope,
    faAmericanSignLanguageInterpreting,
    faAssistiveListeningSystems,
    faLayerGroup,
    faStream,
    faDiceD20,
    faIndustry,
    faAtlas,
    faTorah,
    faHeadphones,
    faExchangeAlt,
    faCommentSlash,
    faSortAmountUp,
    faChartLine,
    faAlignLeft,
    faHome,
    faSpinner,
    faExternalLinkSquareAlt,
    faCreditCard,
    faHdd,
    faEye as fasEye,
    faChalkboardUser,
    faArrowUpAZ
} from "@fortawesome/free-solid-svg-icons";

import {
    faAmazon,
    faBuffer,
    faWatchmanMonitoring,
    faAlipay,
    faPaypal,
    faWeixin,
    faWordpress
} from "@fortawesome/free-brands-svg-icons";

export default {
    install: () => {
        const icons = [
            faSpinner,
            faCogs,
            faCog,
            faGlobe,
            faGlobeAmericas,
            faCoins,
            faMoneyBillWave,
            faMoneyBillAlt,
            faStore,
            faStoreAlt,
            faArchive,
            faBoxes,
            faBoxOpen,
            faBox,
            faProjectDiagram,
            faListAlt,
            faListUl,
            faBars,
            faCodeBranch,
            faShareAlt,
            faUsers,
            faUserTag,
            faUsersCog,
            faUserFriends,
            faUserPlus,
            faGifts,
            faCubes,
            faCube,
            faHandHoldingUsd,
            faMoneyCheckAlt,
            faMoneyCheck,
            faGift,
            faStamp,
            faTasks,
            faHandHoldingHeart,
            faShoppingBasket,
            faUpload,
            faStarHalfAlt,
            faPeopleCarry,
            faIdCardAlt,
            faLuggageCart,
            faShoppingCart,
            faNetworkWired,
            faClipboardList,
            faStethoscope,
            faAmericanSignLanguageInterpreting,
            faAssistiveListeningSystems,
            faObjectGroup,
            faLayerGroup,
            faCopyright,
            faLightbulb,
            faPlusSquare,
            faStream,
            faDiceD20,
            faIndustry,
            faAtlas,
            faTorah,
            faHeadphones,
            faUser,
            faExchangeAlt,
            faComments,
            faCommentSlash,
            faChartBar,
            faSortAmountUp,
            faChartLine,
            faAlignLeft,
            faEye,
            faUserGroup,
            faUsersGear,
            faObjectUngroup,
            faAmazon,
            faBuffer,
            faWatchmanMonitoring,
            faHome,
            faExternalLinkSquareAlt,
            faCreditCard,
            faHdd,
            faAlipay,
            faPaypal,
            faWeixin,
            fasEye,
            faStopCircle,
            faCheckCircle,
            faPlayCircle,
            faPauseCircle,
            faChalkboardUser,
            faWordpress,
            faArrowUpAZ
        ];
        library.add(...icons);
    }
}