export default ({axios}) => {
    return {
        namespaced: true,
        state:{
            props: null
        },
        getters:{
            getProps: state  => {
                return state.props
            }
        },
        mutations:{
            initLoad: (state, {props}) =>{
                state.props = props
            }
        },
        actions:{
            initLoad({commit}){
                axios.post("/shared/constants").then(({data}) => {
                    if(!data.errors.length){
                        commit("initLoad", {props: data.data})
                        return data.data
                    }else{
                        throw new Error(data.errors.join(","))
                    }
                });
            }
        }
    }
}