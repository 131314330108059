export default ({axios}) => {
    class countries {
        static get(searchModel){
            return axios.post("/country/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/country/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/country/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/country/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/country/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }
    }

    return countries
}