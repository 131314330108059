export default ({axios}) => {
    class mwsOrderItems{
        static get(searchModel){
            return axios.post("/mws.orderItem/get",searchModel).then(({data}) => {
                return data
            })
        }
    }

    return mwsOrderItems
}