export default ({axios}) => {
    class buyerRewards {
        static get(searchModel){
            return axios.post("/buyerReward/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getStateStyles(record){
            if(record.state === "WaitingForApproval" || record.state === "Processing"){
                return "color-warning"
            }
            else if(record.state === "Rejected" || record.state === "PaymentFailed")
            {
                return "color-danger"
            }
            else if(record.state === "Completed"){
                return "color-success"
            }
            else{
                return "color-info"
            }
        }

        static reject(model){
            return axios.post("/buyerReward/reject",model).then(({data}) => {
                return data
            })
        }

        static approve(model){
            return axios.post("/buyerReward/approve",model).then(({data}) => {
                return data
            })
        }

        static verify(model){
            return axios.post("/buyerReward/verify",model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/buyerReward/create",model).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/buyerReward/update",model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/buyerReward/remove",{id}).then(({data}) => {
                return data
            })
        }

        static stopPayments(id){
            return axios.post("/buyerReward/stopPayments",{id}).then(({data}) => {
                return data
            })
        }

        static regeneratePayments(id){
            return axios.post("/buyerReward/regeneratePayments",{id}).then(({data}) => {
                return data
            })
        }

        static restoreApprove(id){
            return axios.post("/buyerReward/restoreApprove",{id}).then(({data}) => {
                return data
            })
        }

        static markAsCompleted(id){
            return axios.post("/buyerReward/update", {buyerReward: {id, state: "Completed"}}).then(({data}) => {
                return data
            })
        }

        static importVerify(files){
            return axios.post("/buyerReward/importVerify",files).then(({data}) => {
                return data
            })
        }

        static import(files){
            return axios.post("/buyerReward/import",files).then(({data}) => {
                return data
            })
        }
    }

    return buyerRewards
}