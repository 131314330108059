import Vue from 'vue'
import Router from 'vue-router'

export default ({store}) => {
  Vue.use(Router)

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/Index.vue'),
        meta: {
          title: '前海启晋ERP',
          requiresAuth: true,
        }
      },
      {
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta:{
          title: '前海启晋ERP-登录'
        }
      }
    ]
  });

  router.beforeEach( (to, from, next) => {
    if (to.meta.requiresAuth && !store.getters["auth/authenticated"]) {
      return next('/login');
    }
    if(to.meta.title){
      document.title = to.meta.title
    }

    next();
  });

  return router
}