export default ({axios}) => {
    class UserAsin{
        static get(searchModel) {
            return axios.post("/userAsinMatchRules/get",searchModel).then(({data}) => {
                return data
            })
        }
        
        static upsert(conditions) {
            return axios.post("/userAsinMatchRules/upsert", conditions).then(({data}) => {
                return data
            })
        }
    }

    return UserAsin
}
