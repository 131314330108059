export default ({axios}) => {
    class approvalRecords{
        static get(searchModel){
            return axios.post("/approvalRecord/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getStateClass(record){
            return record.state === "accepted" ? "color-success" : "color-danger"
        }
    }

    return approvalRecords
}
