export default ({axios}) => {
    class permissions {
        static get(searchModel){
            return axios.post("/permissionGroup/get",searchModel).then(({data}) => {
                return data
            })
        }
    }

    return permissions
}