export default ({axios}) => {
    class amzMarketplaces {
        static get(searchModel){
            return axios.post("/amzMarketplace/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/amzMarketplace/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/amzMarketplace/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/amzMarketplace/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(model){
            return axios.post("/amzMarketplace/validatoinRules", model).then(({data}) => {
                return data
            })
        }
    }

    return amzMarketplaces
}
