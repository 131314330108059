import config from "@/config"
import moment from "moment"
const lodash = require("lodash")
const fileDownload = require("js-file-download")

class utilities{
    static fakeFetch(res, duration, error){
        return new Promise((resolve, reject) => {
            if(error){
                reject(error);
            }

            setTimeout(() => {
                resolve(res);
            }, duration || utilities.getEnvConfig().fakefetch.duration);
        });
    }

    static B64Encode(content){
        return btoa(encodeURIComponent(content))
    }

    static B64Decode(content){
        return decodeURIComponent(atob(content))
    }

    static splitByNewlines(str){
        if(str && str.length){
            return str.split(/[\r\n]+/gm).filter(l => l && l.length)
        }
        return null
    }

    static isProduction(){
        return process.env.NODE_ENV === "production";
    }

    static getEnvConfig(){
        const env_config = config[process.env.NODE_ENV || 'development'];
        Object.assign(env_config, config.common);

        return env_config;
    }

    static formatDate(date, withTime = true){
        const config = utilities.getEnvConfig()
        return date ? new moment(date).format(withTime ? config.dateFormats.display : config.dateFormats.displayNoTime) : ""
    }

    static formatCurrencyAmount(amount, currecny){
        return `${currecny.symbol}${amount} ${currecny.name}`
    }

    static getEnumKV(enums){
        const KVs = []
        for(let t in enums){
            KVs.push({
                key: t,
                value: enums[t]
            })
        }

        return KVs
    }

    static transformNumberssToChinese(num){
        const chnNumChar = ["零","壹","贰","叁","肆","伍","陆","柒","捌","玖"];
        const chnUnitSection = ["","万","亿","万亿","亿亿"];
        const chnUnitChar = ["","十","百","千"];

        const numToChn = (num) => {
            const index =  num.toString().indexOf(".");
            if(index !== -1){
                const str = num.toString().slice(index);
                let dot = "点";
                for(let i=1;i<str.length;i++){
                    dot += chnNumChar[parseInt(str[i])];
                }
                return dot ;
            }else{
                return "";
            }
        }

        const sectionToChinese = (section) => {
            let str = '', chnstr = '',zero= false,count=0;   //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
            while(section>0){
                const v = section % 10;  //对数字取余10，得到的数即为个位数
                if(v == 0){                    //如果数字为零，则对字符串进行补零
                    if(zero){
                        zero = false;        //如果遇到连续多次取余都是0，那么只需补一个零即可
                        chnstr = chnNumChar[v] + chnstr;
                    }
                }else{
                    zero = true;           //第一次取余之后，如果再次取余为零，则需要补零
                    str = chnNumChar[v];
                    str += chnUnitChar[count];
                    chnstr = str + chnstr;
                }
                count++;
                section = Math.floor(section/10);
            }
            return chnstr;
        }

        const a = numToChn(num);
        num = Math.floor(num);
        let unitPos = 0;
        let strIns = '', chnStr = '';
        let needZero = false;

        if(num === 0){
            return chnNumChar[0];
        }
        while(num > 0){
            const section = num % 10000;
            if(needZero){
                chnStr = chnNumChar[0] + chnStr;
            }
            strIns = sectionToChinese(section);
            strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
            chnStr = strIns + chnStr;
            needZero = (section < 1000) && (section > 0);
            num = Math.floor(num / 10000);
            unitPos++;
        }

        return chnStr+a;
    }

    static readAsDataURL(blob){
        return new Promise((resolve) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result.replace(/^data:.+;base64,/, ''))
            }
            fr.readAsDataURL(blob)
        })
    }

    static async fetchBlobFromSrc(src){
        const data = await fetch(src);
        const blob = await data.blob();

        return blob
    }

    static async writeSrcToClipboard(src){
        const blob = await utilities.fetchBlobFromSrc(src)
        await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
                [blob.type]: blob
            })
        ])

        return blob
    }

    static download(src, fileName, type='url'){
        if(type === "url"){
            let anchor = document.createElement("a")
            fileName = fileName || src.split('#').shift().split('?').shift().split('/').pop()
            return fetch(src)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    anchor.style.display = 'none';
                    anchor.href = url;
                    anchor.download = decodeURIComponent(fileName)
                    anchor.click();
                    URL.revokeObjectURL(url);
                    anchor.remove()

                    return blob
                })
        }
        else if(type === "blob"){
            return fileDownload(src, decodeURIComponent(fileName))
        }
    }

    static transferSequlizeOrder(sort){
        if(sort.order && sort.prop){
            return [[sort.prop, lodash.isString(sort.order) ? sort.order.toLowerCase() === "descending" ? "desc" : "asc" : "asc" ]]
        }

        return null
    }

    static normalizeSequlizeOrder(sort){
        if(sort && sort.length){
            const [prop, dir] = lodash.isArray(sort) ? sort[0] : sort
            return {
                prop,
                order: lodash.isString(dir) ? dir.toLowerCase() === "desc" ? "descending" : "ascending" : "ascending"
            }
        }

        return null
    }

    static onCascadedDateChanged(type, dateObj){
        const from = dateObj.value[0].value
        const to = dateObj.value[1].value
        if(type === "start" && from){
            if(to){
                dateObj.value[1].value = to >= from ? to : from
            }
        }

        if(type === "end" && to){
            if(from){
                dateObj.value[0].value = from <= to ? from : to
            }
        }
        dateObj.value[1].value = moment(dateObj.value[1].value).endOf('day').toISOString()
    }

    static extractFileName(url){
        return url ? url.split('/').pop().split('#')[0].split('?')[0] : url
    }

    static isOssAuthURL(url){
        if(!lodash.isEmpty(url)){
            try{
                const authKey = new URL(url).searchParams.get("auth_key")
                return !!authKey
            }
            catch {
                return false
            }
        }

        return false
    }

    static waitFor(mills){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, mills)
        })
    }

    static getIncrementIcon(val, type = 'sort-amount'){
        let icon
        if(val > 0){
            icon = `${type}-up`
        }
        else if(val < 0){
            icon = `${type}-down`
        }

        return icon
    }

    static getIncrementColor(val){
        let icon
        if(val > 0){
            icon = "color-success"
        }
        else if(val < 0){
            icon = "color-danger"
        }

        return icon
    }
}

export default utilities
