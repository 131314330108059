export default ({axios}) => {
    class currencies{
        static get(searchModel){
            return axios.post("/currency/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/currency/update", model).then(({data}) => {
                return data
            })
        }

        static add(model){
            return axios.post("/currency/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/currency/remove",{id}).then(({data}) => {
                return data
            })
        }

        static getValidations(editId){
            return axios.post("/currency/validatoinRules", {editId}).then(({data}) => {
                return data
            })
        }
    }

    return currencies
}