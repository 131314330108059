const handler = Object.create(null)

handler.install = function (vue, options) {
    window.addEventListener('unhandledrejection', function (e) {
        if(options.vmInstance.$message){
            options.vmInstance.$message.error(`发生服务器异常: ${e.reason.message}`);
        }
    })
}

export default handler

