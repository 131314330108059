const fieldsToDisplay = {
    name: {
        label: "收款人姓名"
    },
    accountNumber:{
        label: "收款账号"
    },
    branchName:{
        label: "分行名称"
    },
    swiftCode:{
        label: "SwiftCode"
    },
    branchAddress:{
        label: "分行地址"
    },
    bankName:{
        label: "收款行名称"
    },
    loginName:{
        label: "登录名"
    }
}

export default ({axios}) => {
    class paymentAccounts {
        static get(searchModel){
            return axios.post("/paymentAccount/get",searchModel).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/paymentAccount/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/paymentAccount/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/paymentAccount/remove",{id}).then(({data}) => data)
        }

        static setDefault(id){
            return axios.post("/paymentAccount/setDefault",{id}).then(({data}) => data)
        }

        static toogleStatus(id){
            return axios.post("/paymentAccount/toogleStatus",{id}).then(({data}) => data)
        }

        static getValidations(model){
            return axios.post("/paymentAccount/validatoinRules", model).then(({data}) => data)
        }

        static getDisplayFields(entity){
            if(!entity){
                return null
            }

            return Object.entries(entity).reduce((accu,[key, value]) => {
                const fieldConfig = fieldsToDisplay[key]
                if(fieldConfig){
                    accu.push({...fieldConfig, value, key})
                }

                return accu
            }, [])
        }

        static getFieldValues(paymentAccount){
            let name = `[${paymentAccount.paymentType.name}]`
            if(paymentAccount.name){
                name = name + ` - ${paymentAccount.name}`
            }

            if(paymentAccount.accountNumber){
                name = name + ` - ${paymentAccount.accountNumber}`
            }

            if(paymentAccount.loginName){
                name = name + ` - ${paymentAccount.loginName}`
            }

            return name
        }

        static resetFields(paymentAccount){
            paymentAccount.name = null
            paymentAccount.accountNumber = null
            paymentAccount.branchName = null
            paymentAccount.swiftCode = null
            paymentAccount.branchAddress = null
            paymentAccount.bankName = null
            paymentAccount.loginName = null
            paymentAccount.loginPassword = null
            paymentAccount.QRCode = null
        }
    }

    return paymentAccounts
}