export default ({axios, constants}) => {
    class reviewPlans{
        static get(searchModel){
            return axios.post("/reviewPlan/get",searchModel).then(({data}) => {
                return data
            })
        }

        static getValidations(){
            return axios.post("/reviewPlan/validatoinRules").then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/reviewPlan/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/reviewPlan/create", model).then(({data}) => {
                return data
            })
        }

        static cancel(id){
            return axios.post("/reviewPlan/cancel", {id}).then(({data}) => {
                return data
            })
        }

        static approve(planId,  approveRecord){
            return axios.post("/reviewPlan/approve", {planId, approveRecord}).then(({data}) => {
                return data
            })
        }

        static getProgressBarColor(record){
            if(record.progress >= 0.9){
                return "success"
            }else if(record.progress <= 0.3){
                return "exception"
            }
            else if(record.progress > 0.3 && record.progress < 0.9){
                return "warning"
            }
        }

        static getProgressColor(record){
            if(record.progress >= 0.9){
                return "color-success"
            }else if(record.progress <= 0.3){
                return "color-danger"
            }
            else if(record.progress > 0.3 && record.progress < 0.9){
                return "color-warning"
            }
        }

        static getStateStyle(record){
            if(record.state === "canceled" || record.state === "approvalRejected"){
                return "color-danger"
            }
            else if(record.state === "completed"){
                return "color-success"
            }
            else if(record.state === "waitingForApproval"){
                return "color-info"
            }
        }

        static getStateStyleType(record){
            if(record.state === "canceled" || record.state === "approvalRejected"){
                return "danger"
            }
            else if(record.state === "completed"){
                return "success"
            }
            else if(record.state === "waitingForApproval"){
                return "info"
            }
        }

        static getStateInfo(record){
            if(record.state !== "waitingForApproval"){
                return constants.reviewPlans.states[record.state]
            }
            else if(record.nextStep && record.nextStep.approvers){
                const approvers = record.nextStep.approvers.map(approver => `"${approver.fullName}"`).join("或")
                return `等待${approvers}的审批`
            }
        }
    }

    return reviewPlans
}
