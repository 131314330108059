const utils = require("../utilities").default
export default ({axios}) => {
    class paymentSlips {
        static get(searchModel){
            return axios.post("/paymentSlip/get",searchModel).then(({data}) => {
                return data
            })
        }

        static export(searchModel, fileName){
            return axios.post("/paymentSlip/export", {queries: searchModel, fileName}, {
                responseType: 'blob'
            }).then(({data}) => {
                utils.download(data, fileName, "blob")
            })
        }

        static preview(data){
            return axios.post("/paymentSlip/preview",data).then(({data}) => {
                return data
            })
        }

        static getPaymentStateStyles(paymentSlip){
            if(paymentSlip.paymentState === "unpaid"){
                return "color-warning"
            }
            else if(paymentSlip.paymentState === "paid")
            {
                return "color-success"
            }
            else if(paymentSlip.paymentState === "rejected" || paymentSlip.paymentState === "destroyed"){
                return "color-danger"
            }
        }

        static getPaymentStateStyleTypes(paymentSlip){
            if(paymentSlip.paymentState === "unpaid"){
                return "warning"
            }
            else if(paymentSlip.paymentState === "paid")
            {
                return "success"
            }
            else if(paymentSlip.paymentState === "rejected" || paymentSlip.paymentState === "destroyed"){
                return "danger"
            }
        }

        static async done(id, paymentState, rejectComments, screenShot, paidResults){
            return axios.post("/paymentSlip/done",{id, paymentState, rejectComments, screenShot, paidResults}).then(({data}) => {
                return data
            })
        }

        static getValidations(model, opts){
            return axios.post("/paymentSlip/validatoinRules", {model, opts}).then(({data}) => {
                return data
            })
        }

        static update(model){
            return axios.post("/paymentSlip/update", model).then(({data}) => {
                return data
            })
        }

        static create(model){
            return axios.post("/paymentSlip/create", model).then(({data}) => {
                return data
            })
        }

        static remove(id){
            return axios.post("/paymentSlip/remove", {id}).then(({data}) => {
                return data
            })
        }
    }

    return paymentSlips
}
