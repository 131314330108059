export default ({axios}) => {
    class productTypeCategory {
        static get(option) {
            return axios.post("/productTypeCategory/get", option).then(({ data }) => {
                return data
            })
        }

    }

    return productTypeCategory
}